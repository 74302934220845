@import "../main.scss";

.header__container {
  //   padding: 1.8rem 8rem;
  background-color: $dark-600;
  position: relative;
  z-index: 9999;

  //   @include media('<=phone') {
  //     padding: 2rem 0rem;
  // }
}

.header__wrapper {
  display: flex;
  width: 100%;
  padding: 1.8rem 3.2rem;
  justify-content: space-between;
  align-items: center;

  @include media("<=phone") {
    padding: 2rem 1.6rem;
  }
}

.header__left {
  display: flex;
  gap: 4rem;
  align-items: center;
}

.header__logo {
  width: 4.3rem;
  height: auto;
}

.header__links {
  display: flex;
  gap: 3.2rem;

  @include media("<=phone") {
    display: none;
  }
}

.header__link {
  @extend %text-sm;
  color: $white;
  font-weight: 600;
  cursor: pointer;
}

.header__link:hover {
  color: $blue-500;
}

.header__buttons {
  display: flex;
  gap: 1.2rem;
  height: fit-content;

  a {
    text-decoration: none;
    color: $white;
  }

  @include media("<=phone") {
    display: none;
  }
}

.header__button__blue {
  @extend %text-sm;
  padding: 1rem 1.8rem;
  color: $white;
  background-color: $blue-500;
  border: 1px solid $blue-500;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(16, 24, 40, 0.05);
  border-radius: 0.8rem;
  font-weight: 600;
}

.header__button__transparent {
  @extend %text-sm;
  padding: 1rem 1.8rem;
  color: $white;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(16, 24, 40, 0.05);
  border-radius: 0.8rem;
  font-weight: 600;
}

.hamburger__wrapper {
  display: none;
  cursor: pointer;

  @include media("<=phone") {
    display: block;
  }
}

.hamburger {
  padding: 1.4rem 1.1rem;
}

// .menu__desktop__container{
//     position: absolute;
//     bottom: -7rem;
//     left: 10rem;
//     background-color: $dark-600;
//     // width: 100vw;
//     box-shadow: 0rem 1.2rem 1.6rem -0.4rem rgba(16, 24, 40, 0.08), 0px 0.4rem 0.6rem -0.2rem rgba(16, 24, 40, 0.03);
//     border-radius: 1.2rem;
//     padding: 1.2rem;
//     display: grid;
//     gap: 0.8rem;
// }

.menu__desktop__resources {
  position: absolute;
  bottom: -31rem;
  left: 29rem;
  background-color: $dark-500;
  // width: 100vw;
  box-shadow: 0rem 1.2rem 1.6rem -0.4rem rgba(16, 24, 40, 0.08),
    0px 0.4rem 0.6rem -0.2rem rgba(16, 24, 40, 0.03);
  border-radius: 1.2rem;
  padding: 1.2rem;
  display: grid;
  gap: 0.8rem;
  border: 1px solid $dark-400;

  @include media("<=phone") {
    display: none;
  }
}

.menu__desktop__company {
  position: absolute;
  bottom: -15.5rem;
  left: 19rem;
  background-color: $dark-500;
  // width: 100vw;
  box-shadow: 0rem 1.2rem 1.6rem -0.4rem rgba(16, 24, 40, 0.08),
    0px 0.4rem 0.6rem -0.2rem rgba(16, 24, 40, 0.03);
  border-radius: 1.2rem;
  padding: 1.2rem;
  display: grid;
  gap: 0.8rem;
  border: 1px solid $dark-400;

  @include media("<=phone") {
    display: none;
  }
}

.menu__desktop__products {
  position: absolute;
  bottom: -10.3rem;
  left: 9rem;
  background-color: $dark-500;
  // width: 100vw;
  box-shadow: 0rem 1.2rem 1.6rem -0.4rem rgba(16, 24, 40, 0.08),
    0px 0.4rem 0.6rem -0.2rem rgba(16, 24, 40, 0.03);
  border-radius: 1.2rem;
  padding: 1.2rem;
  display: grid;
  gap: 0.8rem;
  border: 1px solid $dark-400;

  @include media("<=phone") {
    display: none;
  }
}

//

.menu__desktop__extra {
  display: flex;
  gap: 1.6rem;
  max-width: 31.2rem;
  cursor: pointer;
  padding: 1.2rem;
  border-radius: 0.4rem;
  outline: none;
  text-decoration: none;

  img {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.menu__desktop__extra:hover {
  background-color: $dark-400;
}

.extra__desktop__title {
  @extend %text-md;
  font-weight: 600;
  color: $white;
}

.extra__desktop__desc {
  @extend %text-sm;
  font-weight: 400;
  color: $dark-100;
}

.hamburger:hover {
  background-color: $dark-500;
  border-radius: 0.4rem;
}

.menu__mobile__wrapper {
  width: 100vw;
  padding: 1rem;
  height: 100%;
  display: grid;
  position: absolute;
  top: 5rem;
  // place-content: center;
}

.menu__mobile {
  display: none;

  background-color: $dark-500;
  width: 100%;
  // margin: 0.5rem;
  // max-width: 28rem;
  right: 0;
  box-shadow: 0rem 1.2rem 1.6rem -0.4rem rgba(16, 24, 40, 0.08),
    0px 0.4rem 0.6rem -0.2rem rgba(16, 24, 40, 0.03);
  border-radius: 1.2rem;
  padding: 2.4rem 0rem;
  border: 1px solid $dark-400;

  p {
    cursor: pointer;
    transition: color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  p:hover {
    color: $blue-400;
  }

  hr {
    border: none;
    outline: none;
    border-top: 1px solid $dark-400;
    width: 100%;
  }

  @include media("<=phone") {
    display: block;
  }
}

.mobile__menu__top {
  @extend %text-md;
  font-weight: 500;
  display: grid;
  gap: 1.6rem;
  padding: 1.2rem 1.6rem;
  color: $white;

  span {
    display: flex;
    justify-content: space-between;

    img {
      width: 2rem;
      height: 2rem;
    }
  }
}

.mobile__menu__bottom {
  @extend %text-md;
  font-weight: 500;
  display: grid;
  gap: 1.6rem;
  padding: 1.2rem 1.6rem;

  a {
    color: $white;
    text-decoration: none;
    outline: none;
  }

  a:hover {
    color: $blue-500;
  }
}

.header__buttons__mobile {
  display: grid;
  width: 100%;
  padding: 1.2rem 1.6rem;

  a {
    text-decoration: none;
    color: $white;
  }
}

.menu__item__with__icon {
  img:hover {
    background-color: $dark-500;
    border-radius: 0.2rem;
  }
}

.embedded__mobile__menu__title {
  @extend %text-sm;
  color: $blue-500;
  font-weight: 600;
}

.embedded__mobile__menu {
  display: grid;
  gap: 1.2rem;
  margin-top: 1.2rem;
}

.embedded__mobile__menu__list {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

// .embedded__mobile__menu__item:hover{
// background-color: $dark-500;
// border-radius: 0.8rem;
// }

.embedded__mobile__menu__item {
  display: flex;
  gap: 1.6rem;
  cursor: pointer;
  width: fit-content;
  text-decoration: none;
  outline: none;

  p {
    @extend %text-md;
    color: $white;
    font-weight: 500;
  }

  img {
    width: 2.4rem;
    height: 2.4rem;
  }
}
