@import '../main.scss';


.testimonials__new__container {
    padding: 0rem 8rem;
    padding-bottom: 9.6rem;

    @include media('<=phone') {
        padding: 0 1.6rem;
        padding-bottom: 6.4rem;
    }

}

.testimonials__two__extra {
    height: 56.6rem;
    max-width: 121.6rem;
    width: 100%;
    // background-color: $dark-600;
    

    @include media('<=phone'){
        display: grid;
        padding: 0;
        width: 100%;
        max-width: unset;
        // height: 81.4rem;
    }
}

.testimonials__new__wrapper{
    // display: flex;
    // justify-content: space-between;
    position: relative;
    // max-height: 56.6rem;
    // height: fit-content;
    height: 100&;
   
    
    // max-width: 121.6rem;
    width: 100%;
    padding: 0rem 3.2rem;

    @include media('<=phone'){
        display: grid;
        padding: 0;
        // width: 100%;
        // max-width: unset;
        // height: 81.4rem;
    }
}

.testimonials__new__content{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100%;
    // background-color: $dark-600;
    height: 56.6rem;
    max-width: 121.6rem;
    position: relative;
    background-color: #1A2130;
    border-radius: 2.4rem;

    @include media('<=phone'){
        display: grid;
        height: 61.6rem;
        // padding: 0;
        // width: 100%;
        // max-width: unset;
        // height: unset;
    }
}

.testimonials__new__text {
    display: grid;
    gap: 3.2rem;
    max-width: 60rem;
    width: 100%;
    padding: 6.4rem;
    height: fit-content;
    

    @include media('<=phone'){
        padding: 4rem 2.4rem;
    }
}

.testimonials__new__title {
  @extend %display-sm;
  font-weight: 500;
  color: $white;

  @include media('<=phone') {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}

.testimonials__new__user {
    display: grid;
    gap: 0.4rem;
}

.testimonial__user__name {
    @extend %text-lg;
    font-weight: 600;
    color: $white;
}

.testimonial__user__about {
    @extend %text-md;
    font-weight: normal;
    color: $dark-100;
}

.testimonials__new__image {
    height: 100%;
    object-fit: cover;
    // width: 48rem;
    border-bottom-right-radius: 2.4rem;
    border-top-right-radius: 2.4rem;
    position: absolute;
    right: 0;
    top: 0;

    @include media ('<=phone'){
        height: 28rem; 
        width: 100%;
        border-radius: 0;
        border-bottom-left-radius: 2.4rem;
        border-bottom-right-radius: 2.4rem;
        right: unset;
        // top: 28.6rem;
        top: unset;
        bottom: 0;
        // position: unset;
    }
}